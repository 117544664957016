import React, { useState } from "react"
import styled, { css } from "styled-components"
import { Box, Row } from "@jobintrans/base-ui.components.atoms._atoms"
import { H3, LabelH2 } from "@jobintrans/base-ui.components.atoms.typography"
import { OfferAdInfo, ShortOfferType } from "@jobintrans/base-ui.types.api"
import Tag from "@jobintrans/base-ui.components.atoms.tag"
import Button from "@jobintrans/base-ui.components.atoms.button"
import Alert from "@jobintrans/base-ui.components.molecules.alert"
import { useNavigate } from "react-router-dom"
import { Trans, useTranslation } from "react-i18next"
import Modal from "@jobintrans/base-ui.components.molecules.modal"
import Icon from "@jobintrans/base-ui.components.atoms.icon"
import { ProfileOfferService } from "@jobintrans/base-ui.services.api-service"
import { useSelector } from "react-redux"
import { useProfileFeature } from "hooks/useProfile"
import { RootState } from "store/store"
import Status from "components/atoms/Status/Status"
import { formatDate } from "utils/date"
import Link from "components/atoms/Link/Link"
import { SentryService } from "services/SentryService"

export const AdInfo = ({
  type,
  isAdmin = false,
  data,
}: {
  type: "facebook" | "google" | "jooble"
  isAdmin?: boolean
  data: OfferAdInfo
}) => {
  const { t, i18n } = useTranslation(["panel", "form", "common", "data"])

  const now = new Date()
  const isBetween = now > new Date(data.startDate) && now < new Date(data.endDate)

  return (
    <AdInfoWrapper>
      <Status label={isBetween ? "active" : "deactivated"} />
      <Row marginTop="XS" alignItems="center" justifyContent="space-between">
        <Row alignItems="center">
          <Icon icon={`icon-${type}`} size="XXL" marginRight="XS" />
          <H3 margin="0">{t(`offers.offer.promotion.${type}.title`)}</H3>
        </Row>
        <Tag
          type="secondary"
          showBorder={false}
          background="background"
          iconColor="primary"
          label={`${formatDate(data.startDate, i18n.language, "dd/MM/yyyy")} -> ${formatDate(
            data.endDate,
            i18n.language,
            "dd/MM/yyyy",
          )}`}
        />
      </Row>
      {data.link && (
        <Box marginTop="S">
          <Link
            type="secondary"
            background="backgroundDarker"
            to={data.link}
            target="_blank"
            width="100%"
          >
            {t(`offers.offer.promotion.common.open`)}
          </Link>
        </Box>
      )}
      {!isAdmin && (
        <Alert marginTop="XS" type="warning" small>
          {t(`offers.offer.promotion.${type}.info`)}
        </Alert>
      )}
    </AdInfoWrapper>
  )
}

const OfferBlock = ({ data: initData }: { data: ShortOfferType }) => {
  const navigate = useNavigate()
  const { profile } = useSelector((state: RootState) => state.profile)
  const hasOfferAnalytics = useProfileFeature("offer-analytics")
  const { t, i18n } = useTranslation(["panel", "form", "common", "data"])

  const [data, setData] = useState<ShortOfferType>(initData)
  const [boosts, setBoosts] = useState<null | number>(initData.boosts)
  const [loadingBoost, setLoadingBoost] = useState<boolean>(false)
  const [modal, setModal] = useState<string | boolean>(false)

  const today = new Date()
  const date = data.status === "pending" ? data.paymentExpiredDate : data.expiredDate
  const dtCreated = new Date(data.dtCreated)
  const expiredDate = new Date(date)
  const totalDays = Math.round((expiredDate.getTime() - dtCreated.getTime()) / (1000 * 3600 * 24))
  const nowDays = Math.round((expiredDate.getTime() - today.getTime()) / (1000 * 3600 * 24))

  const hasAds = Object.keys(data.ads).length > 0

  const handleBoost = async () => {
    try {
      setLoadingBoost(true)
      const res = await ProfileOfferService.boostOffer(`${data.id}`)
      setData(res)
      setBoosts(res.boosts)
      setLoadingBoost(false)
      window.alert(t("offers.offer.boost.alert.success"))
    } catch (e) {
      SentryService.error("[ERROR ProfileOfferService.boostOffer]:", e)
      setLoadingBoost(false)
      window.alert(t("offers.offer.boost.alert.error"))
    }
  }

  return (
    <>
      <Wrapper>
        <Content>
          <Box marginBottom="M">
            <HeaderWrapper>
              <Status label={data.status} />
              <LabelH2 marginBottom="0">{data.name}</LabelH2>
            </HeaderWrapper>
            <TagsWrapper>
              {data.type === "jumper" && (
                <Tag
                  type="secondary"
                  showBorder={false}
                  background="jumper"
                  color="white"
                  label={t("jumper", { ns: "data" })}
                />
              )}
              <Tag
                type="secondary"
                showBorder={false}
                background="background"
                icon="tag"
                iconColor="primary"
                label={`${t(data.category, { ns: "data" })}, ${t(data.subcategory, {
                  ns: "data",
                })}`}
              />
              <Tag
                type="secondary"
                showBorder={false}
                background="background"
                icon="map"
                iconColor="primary"
                label={`${data.address.city}, ${data.address.countryCode.toUpperCase()}${
                  data.type === "jumper" && data.endAddress
                    ? ` -> ${data.endAddress.city}, ${data.endAddress.countryCode.toUpperCase()}`
                    : ""
                }`}
              />
              {data.type === "jumper" && data.startDate && data.endDate && (
                <Tag
                  type="secondary"
                  showBorder={false}
                  background="background"
                  icon="map"
                  iconColor="primary"
                  label={`${formatDate(
                    data.startDate,
                    i18n.language,
                    "do MMMM yyyy",
                  )} -> ${formatDate(data.endDate, i18n.language, "do MMMM yyyy")}`}
                />
              )}
              {data.surveyName && (
                <Tag
                  type="secondary"
                  showBorder={false}
                  background="background"
                  icon="check-square"
                  iconColor="primary"
                  label={data.surveyName}
                />
              )}
              <Tag
                type="secondary"
                showBorder={false}
                background="background"
                icon="calendar"
                iconColor="primary"
                label={`${formatDate(data.dtCreated, i18n.language, "do MMMM yyyy")}${
                  data.expiredDate
                    ? `-> ${formatDate(data.expiredDate, i18n.language, "do MMMM yyyy")}`
                    : ""
                }`}
              />
              {data.lastBoost && (
                <Tag
                  type="secondary"
                  showBorder={false}
                  background="background"
                  icon="boost"
                  iconColor="primary"
                  label={`${formatDate(data.lastBoost, i18n.language, "do MMMM yyyy")}`}
                />
              )}
            </TagsWrapper>
          </Box>
          {/*{(data.status === "active" || data.status === "pending") && nowDays >= 0 && (*/}
          {/*  <TimelineWrapper>*/}
          {/*    <Timeline*/}
          {/*      precent={((totalDays - nowDays) / totalDays) * 100}*/}
          {/*      color={nowDays > 2 ? "primary" : nowDays === 0 ? "red" : "orange"}*/}
          {/*    >*/}
          {/*      /!*<p>{diffrenceInDays}</p>*!/*/}
          {/*    </Timeline>*/}
          {/*    {nowDays === 0 && <Label type="third">Oferta dziś wygaśnie</Label>}*/}
          {/*    {nowDays <= 2 && nowDays > 0 && (*/}
          {/*      <Label type="third">Oferta wygaśnie za {nowDays} dni</Label>*/}
          {/*    )}*/}
          {/*    {nowDays > 2 && <Label type="third">Pozostało {nowDays} dni</Label>}*/}
          {/*  </TimelineWrapper>*/}
          {/*)}*/}
        </Content>
        {data.status === "pending" && (
          <>
            <Alert type="warning" marginBottom="S" small>
              <Trans
                i18nKey={t("offers.offer.pending-info")}
                components={{
                  href: <a target="_blank" rel="noreferrer" href="mailto:pomoc@jobintrans.eu" />,
                }}
              />
            </Alert>
            {data.transaction && (
              <ButtonsWrapper status={data.status}>
                <Button
                  type="secondary"
                  icon="clock"
                  onClick={() => navigate(`/transaction/status/${data.id}/${data.transaction.id}`)}
                >
                  {t("offers.offer.buttons.status")}
                </Button>
              </ButtonsWrapper>
            )}
          </>
        )}
        {data.status === "limited" && (
          <Alert type="warning" marginBottom="S" small>
            <Trans
              i18nKey={t("offers.offer.limited-info")}
              components={{
                href: <a target="_blank" rel="noreferrer" href="mailto:pomoc@jobintrans.eu" />,
              }}
            />
          </Alert>
        )}
        <ButtonsWrapper status={data.status}>
          {(data.status === "active" || data.status === "hidden") && (
            <>
              <a
                href={`${process.env.REACT_APP_DOMAIN}/pl/offers/${data.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button type="secondary" icon="eye">
                  {t("offers.offer.buttons.see")}
                </Button>
              </a>
              <Button
                type="secondary"
                icon="edit"
                onClick={() => navigate(`/edit-offer/${data.id}`)}
              >
                {t("offers.offer.buttons.edit")}
              </Button>
              {hasOfferAnalytics && (
                <Button
                  type="secondary"
                  icon="target"
                  onClick={() => navigate(`m/${data.id}/analytics`)}
                >
                  {t("offers.offer.buttons.statistics")}
                </Button>
              )}
              {hasAds && (
                <Button type="secondary" icon="bell" onClick={() => setModal("promotion")}>
                  {t("offers.offer.buttons.ad")}
                </Button>
              )}
              {data.plan !== "draft" && !!boosts && boosts > 0 && (
                <Button type="secondary" icon="boost" onClick={handleBoost} loading={loadingBoost}>
                  {`${t("offers.offer.buttons.boost")} (${boosts})`}
                </Button>
              )}
              {data.plan === "draft" && (
                <Link type="secondary" icon="clock" to={`/extend-offer/${data.id}`}>
                  {t("offers.offer.buttons.extend")}
                </Link>
              )}
              <Button
                type="primary"
                icon="check-square"
                onClick={() => navigate(`/panel/offers/${data.id}/applications`)}
              >
                {t("offers.offer.buttons.candidates")}
              </Button>
            </>
          )}
          {data.status === "deactivated" && (
            <>
              <Button
                type="primary"
                icon="check-square"
                onClick={() => navigate(`/panel/offers/${data.id}/applications`)}
              >
                {t("offers.offer.buttons.candidates")}
              </Button>
            </>
          )}
          {data.status === "limited" && (
            <>
              <a
                href={`${process.env.REACT_APP_DOMAIN}/pl/offers/${data.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button type="secondary" icon="eye">
                  {t("offers.offer.buttons.see")}
                </Button>
              </a>
              {hasOfferAnalytics && (
                <Button
                  type="secondary"
                  icon="target"
                  onClick={() => navigate(`m/${data.id}/analytics`)}
                >
                  {t("offers.offer.buttons.statistics")}
                </Button>
              )}
              <Link type="secondary" icon="clock" to={`/extend-offer/${data.id}`}>
                {t("offers.offer.buttons.extend")}
              </Link>
              <Button
                type="primary"
                icon="check-square"
                onClick={() => navigate(`/panel/offers/${data.id}/applications`)}
              >
                {t("offers.offer.buttons.candidates")}
              </Button>
            </>
          )}
        </ButtonsWrapper>
      </Wrapper>
      <Modal
        show={modal === "promotion"}
        setShow={setModal}
        title={t("offers.offer.promotion.title")}
      >
        <Ads>
          {data.ads?.facebook && <AdInfo type="facebook" data={data.ads.facebook} />}
          {data.ads?.google && <AdInfo type="google" data={data.ads.google} />}
        </Ads>
      </Modal>
    </>
  )
}

export default OfferBlock

const Timeline = styled(Box)<{
  precent?: number
  color: string
}>`
  position: relative;
  width: 250px;
  max-width: 100%;
  height: 10px;
  background: ${({ theme }) => theme.color.background};
  border-radius: 10px;
  margin-bottom: ${({ theme }) => theme.space.XXS};
  overflow: hidden;

  &:before {
    position: absolute;
    content: "";
    left: 0;
    top: 0;
    width: ${({ precent }) => precent}%;
    height: 100%;
    background: ${({ theme, color }) => theme.color[color]};
  }

  @media (max-width: 1100px) {
    width: 150px;
  }
`

const TimelineWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const ButtonsWrapper = styled(Box)<{
  status: string
}>`
  display: flex;
  justify-content: flex-end;
  grid-gap: ${({ theme }) => theme.space.XS};

  @media (max-width: 1200px) {
    display: grid;
    grid-template-columns: ${({ status }) => (status === "active" ? "1fr 1fr" : "1fr")};

    button {
      width: 100%;
    }
  }
`

const TagsWrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  grid-gap: ${({ theme }) => theme.space.XS};
  margin-bottom: ${({ theme }) => theme.space.XXS};
`

const StyledStatus = styled(Tag)<{
  status: string
}>`
  padding: ${({ theme }) => theme.space.XXS} ${({ theme }) => theme.space.XS};
  border-radius: 50px;

  ${({ status }) =>
    status === "active" &&
    css`
      background: ${({ theme }) => theme.color.greenBackground};

      label {
        color: ${({ theme }) => theme.color.green};
      }
    `};

  ${({ status }) =>
    status === "pending" &&
    css`
      background: ${({ theme }) => theme.color.orangeBackground};

      label {
        color: ${({ theme }) => theme.color.orange};
      }
    `};

  ${({ status }) =>
    status === "deactivated" &&
    css`
      background: ${({ theme }) => theme.color.redBackground};

      label {
        color: ${({ theme }) => theme.color.red};
      }
    `};
`

const HeaderWrapper = styled(Box)`
  display: flex;
  align-items: flex-end;
  grid-gap: ${({ theme }) => theme.space.XS};
  margin-bottom: ${({ theme }) => theme.space.XS};
`

const Content = styled(Box)`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => theme.space.S};

  @media (max-width: 900px) {
    display: grid;
    grid-template-columns: 1fr 1fr;

    button {
      width: 100%;
    }
  }
`

const Wrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.S};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  border: 1px solid ${({ theme }) => theme.color.border};
  background: white;
`

const Ads = styled(Box)`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${({ theme }) => theme.space.S};
`

const AdInfoWrapper = styled(Box)`
  padding: ${({ theme }) => theme.space.S};
  border-radius: ${({ theme }) => theme.variable.borderRadius};
  background: ${({ theme }) => theme.color.background};
`
